"use client";

import { ReactNode } from "react";

import { classNames } from "@saas/shared/utils";

export interface BaseLayoutProps {
  children: ReactNode;
  header: ReactNode;
  className?: string;
}

export const BaseLayout = (props: BaseLayoutProps) => {
  const { children, header, className } = props;

  return (
    <div
      className={classNames("relative flex min-h-screen flex-col", className)}
    >
      {header}

      {children}
    </div>
  );
};

export default BaseLayout;
