"use client";

import { GetLoginFormDataOutput } from "@saas/account/utils";
import { QueryOptionsInterface } from "@saas/shared/utils";

import { invalidateLoginQuery, kratos, useLoginFormQuery } from "../..";

import { LoginFlow } from "@ory/client";
import { useMutation } from "@tanstack/react-query";

export type LoginMutationPayload = {
  identifier: string;
  password: string;
};

interface LoginMutationProps {
  query?: QueryOptionsInterface<LoginFlow, GetLoginFormDataOutput>;
  headers?: Record<string, string>;
}

export const useLoginMutation = (options?: LoginMutationProps) => {
  const {
    data: loginForm,
    isError,
    refetch,
  } = useLoginFormQuery(options?.query);

  const mutation = useMutation(async (data: LoginMutationPayload) => {
    if (!loginForm) {
      await invalidateLoginQuery();

      return Promise.reject("Data sudah tidak berlaku, harap kirim ulang.");
    }

    return kratos
      .updateLoginFlow(
        {
          flow: loginForm.id,
          updateLoginFlowBody: {
            identifier: data.identifier,
            password: data.password,
            csrf_token: loginForm.csrfToken,
            method: loginForm.method.value,
          },
        },
        {
          headers: options?.headers,
        }
      )
      .then(({ data }) => data)
      .catch((error) => {
        throw error.response.data;
      });
  });

  return {
    ...mutation,
    loginForm,
    isError,
    refetch,
  };
};

export default useLoginMutation;
