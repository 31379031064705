"use client";

interface LinkInterface {
  href: string;
  size?: string;
  rel?: string;
  media?: string;
  type?: string;
  color?: string;
}

interface HeadLinksProps {
  links: LinkInterface[];
}

export const HeadLinks = ({ links }: HeadLinksProps) => {
  return (
    <>
      {links.map(({ size, href, rel, media, type, color }) => (
        <link
          key={href}
          rel={rel}
          href={href}
          sizes={size}
          media={media}
          type={type}
          color={color}
        />
      ))}
    </>
  );
};

export default HeadLinks;
