"use client";

import Link from "next/link";
import { useRouter } from "next/router";
import { useCallback, useRef, useState } from "react";

import {
  invalidateUseSessionQuery,
  reloginOauth,
  useLoginMutation,
} from "@saas/account/data-access";
import {
  getLoginFormData,
  LoginFormValues,
  trackLoginError,
  trackLoginSuccess,
} from "@saas/account/utils";
import { env } from "@saas/config/shared";
// TODO: Move reseller to account
// eslint-disable-next-line @nx/enforce-module-boundaries
import { ResellerProfileInterface } from "@saas/marketplace-connection/feature";
import { useAlert } from "@saas/shared/feature";
import { VisibilityIcon, VisibilityOffIcon } from "@saas/shared/icon";
import { BaseButton, BaseInput, Typography } from "@saas/shared/ui";
import { notify, Validator } from "@saas/shared/utils";

import { useSessionProfile } from "..";

import { LoginFlow, Session } from "@ory/client";
import { useForm } from "react-hook-form";
import isEmail from "validator/lib/isEmail";

export interface LoginFormProps {
  getResellerProfile?: () => Promise<ResellerProfileInterface>;
  onLoggedIn: (data: { session: Session }) => void;
  testid?: string;
}

export const LoginForm = ({
  getResellerProfile,
  onLoggedIn,
  testid = "",
}: LoginFormProps) => {
  const isTest = process.env["NODE_ENV"] === "test";
  const formContainer = useRef<HTMLDivElement>(null);
  const [showPassword, setShowPassword] = useState(false);

  const { push, query } = useRouter();
  const [, alertDispatch] = useAlert();
  const { profile } = useSessionProfile();

  const loginChallenge = isTest ? "" : (query["login_challenge"] as string);
  const hasChallenge = !!loginChallenge;

  const { mutateAsync, isLoading, loginForm } = useLoginMutation();

  const {
    register,
    setError,
    handleSubmit,
    formState: { errors },
  } = useForm<LoginFormValues>({
    mode: "onBlur",
  });

  const validator: Validator<LoginFormValues> = {
    identifier: (value) => {
      if (!isEmail(value)) {
        return "Harap isi dengan format yang benar.";
      }

      return true;
    },
  };

  const handleMarketLogin = useCallback(
    async (formData: LoginFormValues) => {
      if (getResellerProfile) {
        try {
          await getResellerProfile();

          await reloginOauth({
            container: formContainer.current,
            data: formData,
            loginChallenge,
          });
        } catch {
          await push(`/register-market?login_challenge=${loginChallenge}`);
        }
      }
    },
    [getResellerProfile, loginChallenge, push]
  );

  const handleLogin = useCallback(
    async (formData: LoginFormValues) => {
      if (profile && hasChallenge) {
        return await handleMarketLogin(formData);
      }

      await mutateAsync(
        {
          identifier: formData.identifier,
          password: formData.password,
        },
        {
          onSuccess: async (data) => {
            const identity = data.session.identity;
            const isVerified = identity.verifiable_addresses
              ? identity.verifiable_addresses[0].verified
              : false;

            trackLoginSuccess(identity.id, identity.traits.name, isVerified);

            if (hasChallenge) {
              return await handleMarketLogin(formData);
            }

            onLoggedIn({
              session: data.session,
            });

            await invalidateUseSessionQuery();
          },
          onError: (error) => {
            trackLoginError();

            if (!hasChallenge && typeof error === "string") {
              return notify(alertDispatch, {
                content: error,
                variant: "negative",
              });
            }

            const flow = error as LoginFlow;

            if (flow.id) {
              const loginData = getLoginFormData(flow);

              if (loginData?.error?.id === 4000006) {
                setError("identifier", {
                  type: "manual",
                  message: "Email atau kata sandi tidak valid.",
                });

                setError("password", {
                  type: "manual",
                  message: "Email atau kata sandi tidak valid.",
                });

                return;
              }

              setError("identifier", {
                type: "manual",
                message: loginData.identifier.error?.text,
              });

              setError("password", {
                type: "manual",
                message: loginData.password.error?.text,
              });
            }

            trackLoginError();
          },
        }
      );
    },
    [
      alertDispatch,
      handleMarketLogin,
      hasChallenge,
      mutateAsync,
      onLoggedIn,
      profile,
      setError,
    ]
  );

  const isDisabled = !hasChallenge && (isLoading || !loginForm?.id);

  return (
    <div ref={formContainer}>
      <form
        className={"flex flex-col gap-4"}
        onSubmit={handleSubmit(handleLogin)}
        data-testid={testid}
      >
        <BaseInput
          label={"Email"}
          type={"email"}
          {...register("identifier", {
            required: "Wajib diisi.",
            validate: validator.identifier,
          })}
          error={!!errors.identifier?.message}
          hint={errors.identifier?.message ?? "Wajib diisi."}
          autoComplete={"email"}
          testid={testid + "__field__email"}
        />

        <BaseInput
          type={showPassword ? "text" : "password"}
          label={"Kata Sandi"}
          {...register("password", {
            required: "Wajib diisi.",
          })}
          action={{
            icon: showPassword ? VisibilityIcon : VisibilityOffIcon,
            onClick: () => setShowPassword(!showPassword),
            testid: testid + "__icon__eye-password",
          }}
          error={!!errors.password?.message}
          hint={errors.password?.message}
          autoComplete={"current-password"}
          testid={testid + "__field__password"}
        />

        <Typography
          as={"p"}
          type={"body-b2"}
          className={"text-button mb-2"}
          data-testid={testid + "__link__forgot-password"}
        >
          <Link href={env.DASHBOARD_URL + "/forgot-password"}>
            Lupa kata sandi?
          </Link>
        </Typography>

        <BaseButton
          variant={"primary"}
          className={"w-full"}
          testid={testid + "__button__login"}
          type={"submit"}
          disabled={isDisabled}
        >
          Login
        </BaseButton>
      </form>
    </div>
  );
};

export default LoginForm;
