import { useRouter } from "next/router";
import { useEffect } from "react";

import { LoginForm, useSessionProfile } from "@saas/account/feature";
import { FeatureFlagsProvider } from "@saas/layout/feature";
import { AppHead, BaseLayout } from "@saas/layout/ui";
import { getResellerProfile } from "@saas/marketplace-connection/data-access";
import { Logo } from "@saas/shared/ui";

import { NextPageWithLayout } from "../_app";

export const LoginPage: NextPageWithLayout = () => {
  const { push, query } = useRouter();
  const { profile } = useSessionProfile();

  const redirectLoggedIn = async () => {
    if (query?.redirect) {
      await push(query.redirect as string);
    } else {
      await push("/");
    }
  };

  useEffect(() => {
    if (profile) {
      redirectLoggedIn();
    }
  }, [profile]);

  return (
    <>
      <AppHead>Login</AppHead>

      <div className={"flex h-full justify-center md:items-center"}>
        <div
          className={"bg-neutral-N0 mx-6 mt-5 h-max rounded-2xl p-6 md:p-24"}
        >
          <div className={"flex flex-col gap-4 md:max-w-[22.5rem] md:gap-6"}>
            <div className={"flex flex-col gap-4"}>
              <div data-testid={"login_text__title"}>
                <h1 className={"title-large"}>Login</h1>
              </div>

              <div data-testid={"login_text__subtitle"}>
                {" "}
                <p className={"text-he body-b1"}>
                  Selamat datang kembali! Masukkan email dan kata sandi
                  terdaftarmu.
                </p>
              </div>
            </div>

            <LoginForm
              testid={"login"}
              getResellerProfile={getResellerProfile}
              onLoggedIn={redirectLoggedIn}
            />
          </div>
        </div>
      </div>
    </>
  );
};

LoginPage.getLayout = function getLayout(page) {
  return (
    <BaseLayout
      className={"bg-blue-B500"}
      header={
        <div className={"sticky top-0 z-10 w-full overflow-visible"}>
          <div
            className={
              "top-0 z-10 flex w-full items-center justify-between bg-transparent px-5 py-4 drop-shadow"
            }
          >
            <div className={"mx-3 flex items-center"}>
              <Logo className={"h-5 w-full md:h-full"} variant={"white"} />
            </div>
          </div>
        </div>
      }
    >
      <FeatureFlagsProvider>{() => page}</FeatureFlagsProvider>
    </BaseLayout>
  );
};

export default LoginPage;
