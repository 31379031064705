"use client";

import NextHead from "next/head";

import { env, manifestLinks } from "@saas/config/shared";
import { MetaProperties } from "@saas/content/ui";
import { HeadLinks } from "@saas/shared/ui";

export interface HeadProps {
  children: string;
}

export const AppHead = ({ children }: HeadProps) => {
  const title = `${children} | ${env.APP_NAME}`;
  return (
    <NextHead>
      <title>{title}</title>

      <link
        rel={"preload"}
        href={"/fonts/Nunito.woff2"}
        as={"font"}
        type={"font/woff2"}
        crossOrigin={"anonymous"}
      />
      <link
        rel={"preload"}
        href={"/fonts/Nunito-Italic.woff2"}
        as={"font"}
        type={"font/woff2"}
        crossOrigin={"anonymous"}
      />

      <HeadLinks links={manifestLinks} />

      <MetaProperties
        properties={{
          title: "Melaka Dashboard",
          description: "Melaka Dashboard",
          url: env.APP_URL + "/login",
          image: {
            type: "image/png",
            url: env.APP_URL + "/images/meta/og-thumbnail.png",
            alt: "Melaka Logo",
          },
        }}
      />
    </NextHead>
  );
};

export default AppHead;
